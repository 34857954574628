import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { ROUTE_TYPE_ONE_WAY } from "../constants";
import { useRouteData } from "../hooks/useRouteData";

import { travelerInfoDefaults } from "../helpers/traveler/travelerInfoDefaults";
import UseAgePoolAndFare from "../hooks/useAgePoolAndFare";
import { DateTime } from "luxon";
import {
  AGENCY_ID,
  ONE_WAY_TRIP_TYPE,
  ROUND_WAY_TRIP_TYPE
} from '../constants';
import axiosCall from "../hooks/axiosCall";

export const SearchContext = React.createContext();

export const SearchProvider = ({ children }) => {
  const [allRoutes] = useRouteData();
  const [selectedRoute, setSelectedRoute] = useState();
  const [availableTrips, setAlltrips] = useState();
  const [selectedTrip, setSelectedTrip] = useState({
    type: "",
    departureTrip: {},
    returnTrip: {},
  });
  const [departureDate, setDepartureDate] = useState(moment());
  const [returnDate, setReturnDate] = useState(moment());
  const [travelerCount, setTravelerCount] = useState(1);
  const [travelerInfo, setTravelerInfo] = useState([travelerInfoDefaults]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showTrips, setShowTrips] = useState(false);
  const [showGuestInfo, setShowGuestInfo] = useState(false);
  const [routeType, setRouteType] = useState(ROUTE_TYPE_ONE_WAY);
  const [total, setTotal] = useState(0);
  const [postObject] = useState({ agencyId: AGENCY_ID });

  const history = useNavigate();

  const dateFormatter = (date) =>
    moment(date).format("YYYY/MM/DD").replace("/", "-").replace("/", "-");

  const travelerUpdateHandler = async ({ evt, idx, inputType, inputName }) => {
    try {
      if (!selectedRoute) {
        history("/");
        return null;
      }

      let name, value;
      let travellerBirthDate;
      let currentFares;
      let cost;
      let ticketClass;
      let country;
      let ageGroup;

      if (inputType === "datetime") {
        setTravelerInfo(
          () =>
          (travelerInfo[idx].birthdate = {
            ...travelerInfo[idx].birthdate,
            [inputName]: evt.value,
          })
        );

        const value = DateTime.fromObject(travelerInfo[idx].birthdate).toFormat(
          "MM-dd-yyyy"
        );

        name = inputName;
        travellerBirthDate = value;

        currentFares = UseAgePoolAndFare(selectedTrip, travellerBirthDate);

        if (selectedTrip.ret) {
          if (travelerInfo[idx]?.ticketClass?.value === "Regular Class") {
            cost = parseInt(currentFares.rate_regular_class.roundtripPrice);
          } else {
            cost = parseInt(currentFares.rate_first_class.roundtripPrice);
          }
        } else {
          if (travelerInfo[idx]?.ticketClass?.value === "Regular Class") {
            cost = parseInt(currentFares.rate_regular_class);
          } else {
            cost = parseInt(currentFares.rate_first_class);
          }
        }
        ageGroup = currentFares.rateType;
        setTotal(() => total + cost);
      } else if (inputName === "ticketClass") {
        ticketClass = evt.value;

        if (selectedTrip.ret) {
          if (evt.value === "Regular Class") {
            cost = parseInt(
              travelerInfo[idx].currentFares.rate_regular_class.roundtripPrice
            );
          } else {
            cost = parseInt(
              travelerInfo[idx].currentFares.rate_first_class.roundtripPrice
            );
          }
        } else {
          if (evt.value === "Regular Class") {
            cost = parseInt(travelerInfo[idx].currentFares.rate_regular_class);
          } else {
            cost = parseInt(travelerInfo[idx].currentFares.rate_first_class);
          }
        }
        setTotal(() => total + cost);
      } else if (inputName === "country") {
        country = evt.value;
      } else {
        name = evt.target.name;
        value = evt.target.value;
      }

      setTravelerInfo(
        travelerInfo.map((item, i) => {
          if (currentFares) {
            return i === idx
              ? {
                ...item,
                currentFares: currentFares,
                [name]: value,
                cost: parseInt(cost),
                ...(ticketClass && {
                  ticketClass: { value: ticketClass, label: ticketClass },
                }),
                ...(country && {
                  country: { value: country, label: country },
                }),
                ...(ageGroup && {
                  ageGroup: ageGroup,
                }),
              }
              : item;
          } else if (cost) {
            return i === idx
              ? {
                ...item,
                cost: parseInt(cost),
                ...(ticketClass && {
                  ticketClass: { value: ticketClass, label: ticketClass },
                }),
                ...(country && {
                  country: { value: country, label: country },
                }),
                ...(ageGroup && {
                  ageGroup: ageGroup,
                }),
              }
              : item;
          } else {
            return i === idx
              ? {
                ...item,
                [name]: value,
                ...(ticketClass && {
                  ticketClass: { value: ticketClass, label: ticketClass },
                }),
                ...(country && {
                  country: { value: country, label: country },
                }),
                ...(ageGroup && {
                  ageGroup: ageGroup,
                }),
              }
              : item;
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const findRoute = async () => {
    if (selectedRoute) {
      let ports = selectedRoute.split("/");

      let finalObject = {
        ...postObject,
        originPortId: ports[0],
        destinationPortId: ports[1],
        departureDate: dateFormatter(departureDate),
        type: routeType === "One Way" ? ONE_WAY_TRIP_TYPE : ROUND_WAY_TRIP_TYPE,
        returnDate: routeType === "oneway" ? null : dateFormatter(returnDate),
      };

      const response = await axiosCall("search/", "post", finalObject);
      setAlltrips(response.data);
      setShowTrips(true);
    }
  }

  useEffect(() => {
    if (selectedRoute) {
      findRoute();
    }
  }, [selectedRoute])

  return (
    <SearchContext.Provider
      value={{
        allRoutes,
        selectedRoute,
        setSelectedRoute,
        selectedTrip,
        setSelectedTrip,
        departureDate,
        setDepartureDate,
        returnDate,
        setReturnDate,
        travelerCount,
        setTravelerCount,
        travelerInfo,
        setTravelerInfo,
        errorMessage,
        setErrorMessage,
        showTrips,
        setShowTrips,
        routeType,
        setRouteType,
        showGuestInfo,
        setShowGuestInfo,
        travelerUpdateHandler,
        total,
        setTotal,
        availableTrips,
        setAlltrips,
        findRoute
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
