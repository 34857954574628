import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { SearchContext } from "../../context/SearchContext";
import Search from "../search/Search";
import HomeStatic from "./HomeStatic";
import TopSection from "./TopSection";
import Result from "../result/Result";
import {
  ROUTE_TYPE_ONE_WAY,
  ROUTE_TYPE_ROUND_TRIP,
} from "../../constants";

const Home = () => {
  const [route, setRoute] = useState(null);
  const location = useLocation();
  const { showTrips, setShowTrips, showGuestInfo, setShowGuestInfo, setRouteType, setTravelerCount, setReturnDate, setDepartureDate, findRoute } =
    useContext(SearchContext);

  useEffect(() => {
    const path = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.size > 0) {
      const type = searchParams.get("type");
      const travelers = searchParams.get("travelers");
      const route1 = searchParams.get("route");
      const depart = searchParams.get("depart");
      const returnDate = searchParams.get("return");

      if (type && type === "round-trip") {
        setRouteType(ROUTE_TYPE_ROUND_TRIP);

        if (depart) {
          setDepartureDate(depart);
        }

        if (returnDate) {
          setReturnDate(returnDate);
        }

        if (travelers && travelers > 0) {
          setTravelerCount(travelers);
        }

        if (route1) {
          setRoute(route1);
        }

        if (route1 && returnDate && depart && travelers && travelers > 0) {
          findRoute();
        }


      } else if (type && type === "one-way") {
        setRouteType(ROUTE_TYPE_ONE_WAY);

        if (route1) {
          setRoute(route1);
        }

        if (depart) {
          setDepartureDate(depart);
        }

        if (travelers && travelers > 0) {
          setTravelerCount(travelers);
        }

        if (route1 && depart && travelers && travelers > 0) {
          findRoute();
        }

      }

    }

    // @Todo might move this to a central hook
    if (showTrips && path === "/") {
      setShowTrips(false);
    }
    if (showGuestInfo) {
      if (path.includes("trips") || path.includes("search")) {
        setShowGuestInfo(false);
      }
    }
  }, [location]);

  return (
    <>
      <TopSection showTrips={showTrips}>
        <Search route={route} />
        {showTrips && <Result />}
      </TopSection>
      {!showTrips && <HomeStatic />}
    </>
  );
};

export default Home;
